.arrowButton {
    width: 38px;
    height: 38px;
    padding: 11px;
    border-radius: 4px;
    border: 1px;
    margin: 0 auto 16px;
    background-color: #E4E7EB;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.arrowButton:last-of-type {
    margin-bottom: 0;
}