.tableHead {
    background: #018DCA;
    height: 69px;
}

.tableHead th {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}


.MuiBreadcrumbs-separator {
    color: #000;
    font-weight: 700;
}